import React, { useCallback, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { gsap } from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import ScrollToPlugin from "gsap/ScrollToPlugin"
import { easeInOut, longAnim } from "utils/gsapAnims"
import { useIsMounted } from "utils/useIsMounted"
import Card from "components/blocks/card"
import Title from "components/elements/Title"
import CareCard from "./card"
import CareNav from "./nav"
import "./careServices.scss"

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

const CareServices = ({ services, title }) => {
  const titles = services.map(service => service.cmsEntryTitle)
  const [activeTitle, setActiveTitle] = useState(titles[0])
  const chapteredScrollRef = useRef(null)
  const navRef = useRef(null)
  const isMounted = useIsMounted()

  const navHeight = useCallback(() => {
    return navRef.current.clientHeight
  }, [navRef])

  const handleClick = e => {
    e.preventDefault()
    const target = e.target.getAttribute("href")

    gsap.to(window, {
      duration: longAnim,
      ease: easeInOut,
      overwrite: true,
      scrollTo: { offsetY: navHeight, y: target },
    })
  }

  const setActive = useCallback(
    (links, index, title) => {
      links.forEach(link => link.classList.remove("-active"))
      links[index].classList.add("-active")

      if (isMounted.current) {
        setActiveTitle(title)
      }
    },
    [isMounted]
  )

  useEffect(() => {
    const q = gsap.utils.selector(chapteredScrollRef.current)
    const navLinks = q(".chaptered-scroll__nav--link")
    const cards = q(".chaptered-scroll__card")

    const tl = gsap.timeline({
      scrollTrigger: {
        end: "bottom top",
        start: "top top",
        toggleClass: {
          className: "-active",
          targets: navRef.current,
        },
        trigger: chapteredScrollRef.current,
      },
    })

    navLinks.forEach((link, index) => {
      const id = link.getAttribute("data-id")
      const title = titles[index]

      tl.to(id, {
        scrollTrigger: {
          end: "bottom +=50%",
          onEnter: () => {
            setActive(navLinks, index, title)
          },
          onEnterBack: () => {
            setActive(navLinks, index, title)
          },
          start: "top +=50%",
          trigger: id,
        },
      })
    })

    cards.forEach(card => {
      tl.to(card, {
        scrollTrigger: {
          end: "bottom +=60%",
          start: "top +=60%",
          toggleClass: {
            className: "-show",
            targets: card,
          },
          trigger: card,
        },
      })
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section className="chaptered-scroll" ref={chapteredScrollRef}>
      <div className="chaptered-scroll__wrapper max-container">
        <Card title={title} />
      </div>
      <CareNav
        activeTitle={activeTitle}
        links={titles}
        onClick={handleClick}
        ref={navRef}
      />
      {services.map((service, i) => (
        <CareCard {...service} key={i} />
      ))}
    </section>
  )
}

CareServices.props = {
  services: PropTypes.arrayOf(PropTypes.shape(CareCard.props)),
  title: PropTypes.shape(Title.props),
}

CareServices.propTypes = CareServices.props

export default CareServices
